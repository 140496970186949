<template>
  <div v-loading="loading">
    <web-header active-url="/books" borderBottom></web-header>
    <div class="book-detail">
      <div class="f-wrap">
        <div class="f-wrap-container">
          <div class="f-wrap-container-header">
            <div class="f-wrap-container-header-left" v-if="info.litpic && info.litpic.img">
              <el-image :src="info.litpic.img"></el-image>
            </div>
            <div class="f-wrap-container-header-right">
              <div class="f-wrap-container-header-right-hed">
                <span class="name" v-if="info.title">{{ info.title }}</span>
                <div class="desc" v-if="info.author">
                  <span class="fa fa-circle-thin"></span>
                  <span>作　者 : {{ info.author }}</span>
                </div>
                <div class="desc" v-if="info.publisher">
                  <span class="fa fa-circle-thin"></span>
                  <span>出版方 : {{ info.publisher }}</span>
                </div>
              </div>
              <div class="f-wrap-container-header-right-introduce" v-if="info.desc">
                <span class="name">作者简介</span>
                <div class="desc">
                  <span class="fa fa-circle-thin"></span>
                  <span>{{ info.desc }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="f-wrap-container-content">
            <div class="f-wrap-container-content-left">
              <div class="f-wrap-container-content-left-hed">
                <div class="title">
                  <span>内容简介</span>
                </div>
              </div>
              <div class="f-wrap-container-content-left-content">
                <div class="scroll-wrap">
                  <div class="scroll-wrap-html" v-html="info.content"></div>
                </div>
                <div class="scrollbar"></div>
              </div>
              <div class="f-wrap-container-content-left-foot">
                <div class="f-wrap-container-content-left-foot-share">
                  <div class="web-site-share">
                    <div class="share-label">分享到:</div>
                    <div class="share-box bdsharebuttonbox">
                      <a href="javascript:void(0);" class="share-item icon-wchat" data-cmd="weixin" title="分享到微信"></a>
                      <a href="javascript:void(0);" class="share-item icon-sinawb" data-cmd="tsina" title="分享到新浪微博"></a>
                      <a href="javascript:void(0);" class="share-item icon-qq" data-cmd="sqq" title="分享到QQ好友"></a>
                      <a href="javascript:void(0);" class="share-item icon-qzone1 popup_twi" onclick="return false;" data-cmd="twi" title="分享到Twitter"></a>
                      <a href="javascript:void(0);" class="share-item icon-qzone1 icon-qzone2 popup_print" onclick="return false;" data-cmd="print" title="打印"></a>
                      <a href="javascript:void(0);" class="share-item icon-smore" data-cmd="more" title="更多分享"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="f-wrap-container-content-right">
              <div class="f-wrap-container-content-right-hed" v-if="info.about_author && info.about_author.img && info.about_author.img.length > 0">
                <img src="../../assets/author.jpg" />
                <span>关于作者</span>
              </div>
              <div class="f-wrap-container-content-right-author" v-if="info.about_author && info.about_author.img && info.about_author.img.length > 0">
                <el-carousel height="445px" indicator-position="none">
                  <el-carousel-item v-for="(item, index) in info.about_author.img" :key="index">
                    <div class="con">
                      <img :src="item" />
                      <div class="name">
                        <img src="../../assets/r_1.png" />
                        <span>{{ info.about_author.title[index] }}</span>
                        <img src="../../assets/l_1.png" />
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <template v-for="(item, index) in relationList">
                <div style="margin-top: 30px;" :key="index">
                  <div class="f-wrap-container-content-right-hed">
                    <img src="../../assets/book_2.jpg" v-if="index" />
                    <img src="../../assets/book.jpg" v-else />
                    <span>{{ item.name }}</span>
                  </div>
                  <div class="f-wrap-container-content-right-scroll">
                    <div class="f-wrap-container-content-right-scroll-list">
                      <div class="f-wrap-container-content-right-scroll-list-item" v-for="(jtem, jndex) in item.list" :key="jndex">
                        <a :href="jtem.url+jtem.class_id+'/'+jtem.id" target="_blank">
                          <span class="fa fa-circle-thin"></span>
                          <span>{{ jtem.title }}</span>
                        </a>
                        <div class="date">{{ dateTimeFormats("YYYY-MM-DD", jtem.send_time) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <web-navigation></web-navigation>
    <web-footer></web-footer>
    <web-right-menu class="right-menu"></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import WebNavigation from "@/components/webNavigation";
import { articleDetail, articleList, articleSection } from "@/api";
export default {
  name: 'BookDetail',
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu,
    WebNavigation
  },
  data () {
    return {
      loading: false,
      info: {},
      relationList: [],
    }
  },
  async mounted () {
    await this.loadExternalScript("http://bdimg.share.baidu.com/static/api/js/share.js?v=89860593.js");
    window._bd_share_config = {
      "common": {
        "bdSnsKey":{},
        "bdText":"",
        "bdMini":"2",
        "bdMiniList":false,
        "bdPic":"",
        "bdStyle":"0",
        "bdSize":"24"
      },
      "share":{}
    }
  },
  created() {
    if (this.$route.params.cid && this.isNumber(this.$route.params.cid) && this.$route.params.id && this.isNumber(this.$route.params.id)) {
      this.getInfoData(this.$route.params.cid, this.$route.params.id);
      this.getTabList(this.$route.params.cid, this.$route.params.id)
    } else {
      this.$message.warning("数据获取失败，请重试");
      this.$router.back();
    }
  },
  methods: {
    // 获取书籍详情
    async getInfoData(cid, id) {
      this.loading = true;
      let res = await articleDetail(cid, id);
      if (res && res.code == 200) {
        if (res.data && res.data.jump_link) {
          this.routerLink(res.data.jump_link);
        }
        this.info = res.data;
        if (this.info.about_author) {
          this.info.about_author = JSON.parse(this.info.about_author);
        }
        this.setPageSeo(this.info.seo_title, this.info.keywords, this.info.seo_desc);
      }
      this.loading = false;
    },
    // 获取分类的下级分类
    async getTabList(cid, id) {
      let param = {
        orderBy: "",
        sortedBy: "",
        search: "parent_id:7",
        searchFields: "parent_id:="
      };
      let res = await articleSection(param);
      if (res && res.code == 200) {
        this.tabList = res.data.list;
        if (res.data.list && res.data.list.length > 0) {
          let activeTemp = { name: "", url: "/book_detail/", list: [] };  // 当前分类的对象
          let otherTemp = { name: "", url: "/news_detail/", list: [] };   // 非当前分类的对象
          for (let i = 0; i < res.data.list.length; i++) {
            let item = res.data.list[i];
            if (cid == item.id) {
              activeTemp.name = item.class_name;
              activeTemp.list = await this.getRelatedData(id, item.id);
            } else {
              otherTemp.name = item.class_name;
              otherTemp.list = await this.getRelatedData(id, item.id);
            }
          }
          if (activeTemp.name && activeTemp.list.length > 0) {
            this.relationList.push(activeTemp);
          }
          if (otherTemp.name && otherTemp.list.length > 0) {
            this.relationList.push(otherTemp);
          }
        }
      }
    },
    // 获取相关内容
    async getRelatedData(msgId, cid) {
      let param = {
        pageSize: 10,
        currentPage: 1,
        orderBy: "send_time,sort,topping",
        sortedBy: "desc",
        search: "",
        searchFields: ""
      };
      if (msgId) {
        param.search = `id:${msgId}`;
        param.searchFields = "id:<>";
      }
      let arr = [];
      let res = await articleList(param, cid);
      if (res && res.code == 200) {
        arr = res.data.list;
      }
      return arr;
    },
    // 时间格式化
    dateTimeFormats(format, e) {
      return this.$moment(e).format(format);
    }
  }
}
</script>
<style lang="scss" scoped>
.book-detail {
  padding-top: 1px;
  padding-bottom: 1px;
  background-color: #f7f7f7;
  .f-wrap {
    &-container {
      &-header {
        height: auto;
        background: #ffffff;
        margin-top: 130px;
        padding-bottom: 75px;
        display: flex;
        &-left {
          min-width: 308px;
          max-width: 308px;
          height: 427px;
          margin-left: 80px;
          margin-top: -55px !important;
        }
        &-right {
          margin: 0 70px 0 30px;
          &-hed {
            margin-top: 100px;
            display: flex;
            flex-direction: column;
          }
          &-introduce {
            margin-top: 30px;
            .desc {
              margin-bottom: 10px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .name {
            font-size: 18px;
            color: #333333;
            display: block;
            margin-bottom: 10px;
          }
          .desc {
            display: flex;
            align-items: baseline;
            span {
              text-align: justify;
            }
            .fa-circle-thin {
              color: #004898;
              font-size: 10px;
              height: 20px;
              display: inline-block;
              line-height: 20px;
              margin-right: 5px;
              font-weight: bold;
            }
          }
        }
      }
      &-content {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        margin-bottom: 100px;
        &-left {
          width: 823px;
          height: 100%;
          background: #ffffff;
          &-hed {
            width: 654px;
            border-bottom: 1px solid #abc5ee;
            margin-left: 71px;
            margin-top: 50px;
            .title {
              background: url("~@/assets/book_title_bg.jpg");
              width: 143.5px;
              background-size: auto !important;
              transform: skewX(-18deg);
              -webkit-transform: skewX(-18deg);
              -ms-transform: skewX(-18deg);
              height: 53px;
              line-height: 53px;
              text-align: center;
              color: #ffffff;
              font-size: 18px;
              span {
                display: block;
                transform: skewX(18deg);
                font-size: inherit;
                line-height: inherit;
                color: inherit;
              }
            }
          }
          &-content {
            position: relative;
            height: 1132px;
            margin-top: 5px;
            .scroll-wrap {
              margin-left: 55px;
              margin-right: 50px;
              height: 100%;
              overflow-y: scroll;
              &-html {
                padding-left: 16px;
                padding-right: 40px;
              }
              ::v-deep p {
                font-size: 14px;
                display: block;
                line-height: 32px;
                color: #707070;
              }
              /* 整个滚动条 */
              &::-webkit-scrollbar {
                width: 16px; /* 对于垂直滚动条，设置宽度 */
              }

              /* 滚动条轨道 */
              &::-webkit-scrollbar-track {
                background: #faf9f9; /* 背景色 */
                border-radius: 10px;
              }

              /* 滚动条滑块 */
              &::-webkit-scrollbar-thumb {
                background: #004898; /* 滑块颜色 */
                border-radius: 10px;
              }
            }
          }
          &-foot {
            &-share {
              margin-left: 75px;
              margin-right: 100px;
              border-top: 1px solid #f7f7f7;
              padding-top: 14px;
              padding-left: 0px;
              padding-bottom: 70px;
            }
          }
        }
        &-right {
          width: 392px;
          height: 1305px;
          &-hed {
            width: 100%;
            height: 40px;
            margin-top: 30px;
            border-bottom: 2px solid #004898;
            font-size: 20px;
            color: #333333;
            display: flex;
            align-items: center;
            &:first-child {
              margin-top: 0;
            }
            img {
              width: 36px;
              height: 31px;
              margin-right: 15px;
            }
          }
          &-author {
            width: 100%;
            height: 445px;
            background: #ffffff;
            .con {
              width: 234px;
              margin: 0 auto;
              padding-top: 40px;
              img {
                width: 100%;
                border-radius: 5px;
              }
              .name {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  width: 80px;
                  height: 6px;
                  border-radius: 0px !important;
                }
                span {
                  margin: 0 5px;
                  font-size: 18px;
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-align: center;
                }
              }
            }
          }
          &-scroll {
            height: 334px;
            padding: 15px;
            overflow: hidden;
            background: url("~@/assets/book_title_bg.jpg");
            &-list {
              height: 100%;
              padding-right: 15px;
              overflow-y: scroll;
              &-item {
                font-size: 16px;
                padding: 15px 10px;
                color: #ffffff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #3e52a7;
                a {
                  flex: 1;
                  display: block;
                  align-items: center;
                  white-space: nowrap; /* 确保文本在一行内显示 */
                  overflow: hidden; /* 超出容器部分隐藏 */
                  text-overflow: ellipsis; /* 超出部分显示省略号 */
                  .fa-circle-thin {
                    min-width: 20px;
                    text-align: center;
                    font-size: 12px;
                  }
                }
                .date {
                  min-width: 100px;
                  text-align: right;
                }
              }
              /* 整个滚动条 */
              &::-webkit-scrollbar {
                width: 10px; /* 对于垂直滚动条，设置宽度 */
              }
              /* 滚动条轨道 */
              &::-webkit-scrollbar-track {
                background: #31438c; /* 背景色 */
                border-radius: 10px;
              }
              /* 滚动条滑块 */
              &::-webkit-scrollbar-thumb {
                background: #4357ac; /* 滑块颜色 */
                border-radius: 10px;
              }
            }
          }
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .book-detail {
    .f-wrap {
      width: 100%;
      padding: 0;
      &-container {
        &-header {
          padding: 0 10px;
          flex-direction: column;
          &-left {
            width: 90%;
            margin-left: 10%;
            height: auto;
          }
          &-right {
            &-hed {
              margin-top: 50px;
            }
            .name {
              font-size: 28px;
            }
            .desc {
              font-size: 22px;
              .fa-circle-thin {
                font-size: 16px;
                height: auto;
                line-height: auto;
              }
            }
          }
        }
        &-content {
          flex-direction: column;
          &-left {
            width: calc(100% - 60px);
            margin-left: 30px;
            margin-right: 30px;
            &-hed {
              width: 90%;
              margin-left: 10px;
            }
            &-content {
              .scroll-wrap {
                margin-left: 0;
                margin-right: 5px;
                &-html {
                  padding-right: 16px;
                }
                ::v-deep p {
                  font-size: 26px;
                  line-height: 1.8;
                }
                &::-webkit-scrollbar {
                  width: 8px; /* 对于垂直滚动条，设置宽度 */
                }
              }
            }
            &-foot {
              &-share {
                margin: 0;
                padding-left: 10px;
              }
            }
          }
          &-right {
            width: calc(100% - 60px);
            height: auto;
            margin: 0 30px;
            &-hed {
              font-size: 26px;
              height: auto;
              padding-bottom: 10px;
              &:first-child {
                margin-top: 30px;
              }
            }
            &-author {
              height: auto;
              padding-bottom: 30px;
              .con {
                width: 70%;
                .name {
                  margin: 20px 0;
                  img {
                    width: calc((100% / 2) - 50px);
                    height: 13px;
                  }
                  span {
                    width: 100px;
                    font-size: 26px;
                  }
                }
              }
            }
            &-scroll {
              &-list {
                &-item {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .book-detail {
    .f-wrap {
      width: 100%;
      padding: 0;
      &-container {
        &-header {
          padding: 0 10px;
          flex-direction: column;
          &-left {
            width: 100%;
            margin-left: 0;
            height: auto;
          }
          &-right {
            margin: 0 auto;
            &-hed {
              margin-top: 50px;
            }
          }
        }
        &-content {
          flex-direction: column;
          &-left {
            width: calc(100% - 30px);
            margin-left: 15px;
            margin-right: 15px;
            &-hed {
              width: 90%;
              margin-left: 10px;
            }
            &-content {
              .scroll-wrap {
                margin-left: 0;
                margin-right: 5px;
                &-html {
                  padding-right: 16px;
                }
                &::-webkit-scrollbar {
                  width: 8px; /* 对于垂直滚动条，设置宽度 */
                }
              }
            }
            &-foot {
              &-share {
                margin: 0;
                padding-left: 10px;
              }
            }
          }
          &-right {
            width: calc(100% - 60px);
            height: auto;
            margin: 0 30px;
            &-hed {
              height: auto;
              padding-bottom: 10px;
              &:first-child {
                margin-top: 30px;
              }
            }
            &-author {
              height: auto;
              padding-bottom: 30px;
              .con {
                width: 70%;
                .name {
                  margin: 20px 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>